.container {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 40px;
}

.label {
  display: flex;
  flex: 1;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
}
.dots {
  display: flex;
  background-color: red;
  flex: 0.6;
}

.value {
  display: flex;
  text-align: right;
  align-items: flex-end;
  justify-content: center;
}
