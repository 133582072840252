.centering {
  align-items: center;
  justify-content: center;
  padding: 8;
}

.activeBetsText {
  color: gray;
  font-size: 13px;
  font-weight: 700;
}

.poolSummarySection {
  padding: 10px 20px 0px 20px;
  background-color: black;
}

.totalText {
  font-weight: bold;
  font-size: 17px;
  margin-right: 10px;
  color: white;
}

.titleText {
  font-size: 18;
  font-weight: bold;
  margin-bottom: 3;
  color: var(--sparket-gold);
}

.winConditionContainer {
  overflow: hidden;
}

.winConditionText {
  font-size: 13;
  color: white;
}

.poolStartText {
  margin-top: 5px;
  font-size: 14;
  color: white;
}
