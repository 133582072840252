.noBetsTextContainer {
  display: flex;
  justify-content: center;
  margin-top: 15px;
  color: white;
}

.titleRow {
  padding-left: 20px;
  padding-top: 10px;
  display: flex;
  align-items: center;
}
