.container {
  display: flex;
}

.titleText {
  font-size: 17px;
  padding-right: 15px;
  margin-bottom: 5px;
}

.labelText {
  color: gray;
  font-size: 13px;
  font-weight: 700px;
}

.oddsText {
  font-size: 15px;
}
