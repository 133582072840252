.container {
  padding: 10px;
}

.text {
  color: white;
}

.oddsAndPayout {
  margin-top: 20px;
  flex-direction: row;
  display: flex;
  justify-content: space-between;
}

.balanceInputContainer {
  margin-top: 40px;
}

.availableBalanceText {
  margin: 5px;
}

.winCondition {
  margin-top: 50px;
}

.titleText {
  font-weight: bold;
  font-size: 18px;
}
.modalTitle {
  margin-left: 20px;
  align-self: center;
}

.topBar {
  font-size: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
}

.placeBetButtonContainer {
  display: flex;
  height: 50px;
  border-radius: 5;
  justify-content: center;
}

.numberInputContainer {
  display: flex;
  margin-bottom: 50px;
  height: 50px;
  border-radius: 5;
  justify-content: center;
}

.betButton {
  width: 75%;
}

.highlight {
  background-color: rgba(255, 205, 0, 0.8);
  color: black;
  transition: 0.3s ease-in-out;
}
