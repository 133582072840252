.itemContainer {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 20px;
  margin-right: 20px;
  background-color: white;
  border: none;
  border-radius: 5px;
  box-shadow: 1.4px 2.9px 2.9px hsl(0deg 0% 0% / 0.46);
  cursor: pointer;
  justify-content: space-between;
}

.leftColumnContainer {
  display: flex;
  flex-direction: column;
  flex: 0.6;
  margin-left: 10px;
  padding-top: 15px;
  padding-bottom: 10px;
  justify-content: space-between;
}

.middleColumnContainer {
  display: flex;
  flex: 0.35;
  align-items: center;
}

.rightColumnContainer {
  display: flex;
  flex: 0.1;
  align-items: center;
  justify-content: center;
}

.poolNameText {
  font-size: 14px;
  margin-bottom: 5px;
}

.closeDateText {
  font-size: 12px;
}

.activeBetsText {
  color: gray;
  font-size: 15px;
  font-weight: 600;
}

.inline {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.poolCloseDatePrefix {
  display: inline;
  color: gray;
  font-size: 10px;
  margin-right: 5px;
}