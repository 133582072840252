.betAmountAndResultRowContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.resultsItem {
  align-items: baseline;
  display: flex;
}

.yourBetLabelText {
  color: gray;
  font-size: 10px;
  margin-right: 5px;
}

.betAmountText {
  font-size: 12px;
  margin-right: 10px;
}

.closeContainer {
  display: flex;
  align-items: center;
}
