@import url("https://fonts.googleapis.com/css2?family=Chakra+Petch:wght@300;450;600;700&family=Open+Sans&display=swap");

:root {
  scrollbar-color:gray var(--sparket-black);
}

div.fade.show {
  pointer-events: auto i !important;
  z-index: 1000;
}

div.fade {
  opacity: inherit;
  pointer-events: auto;
}

.carousel-control-next,
.carousel-control-prev {
  width: 5% !important;
}

:root {
  --gan-red: #e5322d;
  --sparket-gold: #f5b81d;
  --sparket-light-gold: #ffedbb;
  --sparket-light-gray: #f1f2f2;
  --sparket-black: #111920;
  --sparket-green: #66c085;
  --sparket-dark-green: #00834b;
  --sparket-light-green: #d5efdd;
  --sparket-dark-red: #c00020;
  --sparket-light-red: #fcd7d3;
  --sparket-dark-orange: #cb6f00;

  --toastify-icon-color-info: var(--sparket-gold) !important;
  --toastify-icon-color-success: var(--sparket-green) !important;
  --toastify-icon-color-warning: var(--sparket-gold) !important;
  --toastify-icon-color-error: var(--sparket-dark-red) !important;

  .Toastify__toast-body a {
    color: var(--sparket-gold);
  }
}

.Toastify__close-button--light {
  color: unset;
  color: var(--sparket-gold) !important;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

iframe#wagerwire-iframe {
  z-index: -1;
}

.wagerwire-iframe-visible iframe#wagerwire-iframe {
  z-index: 3;
}

.nav-link {
  padding: 0.4rem 0.1rem !important;
}