.container {
  height: 66px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.logoContainer {
  height: 40px;
  margin-left: 15px;
}

.groupNameText {
  color: white;
  font-size: 25px;
}

.clickableGroupNameText {
  cursor: pointer;

  &:hover {
    color: var(--sparket-gold);
  }
}

.profilePicContainer {
  margin-right: 4px;
  height: 40px;
  align-self: flex-start;
  margin-top: 5px;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.profilePic {
  width: 40px;
  height: 40px;
}
