.singleSelectionItemLeftColumnContainer {
    flex: 0.85;
}

.singleSelectionItemRightColumnContainer {
    display: flex;
    flex: 0.15;
    align-items: center;
    justify-content: center;
    border-radius: 0 5px 5px 0;
}
