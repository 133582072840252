.titleText {
  font-size: 12px;
  color: var(--sparket-gold);
}
.titleContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  height: 28px;
  padding-left: 25px;
  padding-right: 18px;
  width: 100%;
}
