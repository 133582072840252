@import "bootstrap/dist/css/bootstrap";

.btn {
  @extend .btn;
  background-color: var(--sparket-green);
  border: none;

  &:hover {
    background-color: #176231;
  }
}

.formContainer {
  position: absolute;
  width: -webkit-fill-available;
  width: -moz-available;
  z-index: 1000;
  display: flex;
  justify-content: center;
  top: -10px;
  opacity: 0.85;
}
