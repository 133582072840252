.titleRow {
  display: flex;
  justify-content: space-between;
  padding: 6px;
  font-size: 20px;
  flex-wrap: wrap;
}

.totalContestants {
  display: flex;
  justify-content: center;
}

.fullScreen {
  background-color: white;
}

.filterRow {
  display: flex;
  padding-top: 5px;
  padding-left: 5px;
  flex-wrap: wrap;
}
